<template>
  <main class="position-relative">
    <template v-if="(Loading === false)">
      <CRow>
        <CCol lg="5" col="12">
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.Info') }}
              <div class="card-header-actions d-flex">
                <CButton color="success" type="button" size="sm" class="py-0 px-2 mr-1" @click="Copy(`https://${$store.state.user.permission.Domain}/cart/confirm?id=${Data._id}`)">
                  {{ $t('Order.CopyOrderLink') }}
                </CButton>
                <CButton v-if="!Edit.Info" color="secondary" type="button" size="sm" class="py-0 px-2" @click="EditData('Info')">
                  {{ $t('Global.Edit') }}
                </CButton>
                <template v-if="Edit.Info">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.Info = false">
                    {{ $t('Global.Cancel') }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="Update('Info')">
                    {{ $t('Global.Save') }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner color="info"/>
              </CElementCover>
              <div class="mb-3">
                <h6>{{ $t('Order.OrderNum') }}</h6>
                <p>
                  <span class="mr-2">{{ Data.OrderNum }}</span>
                  <a href="#" v-c-tooltip="{content: '複製訂單編號', placement: 'top'}" @click="Copy(Data.OrderNum)" class="text-dark">
                    <i class="far fa-copy" />
                  </a>
                </p>
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.OrderLink') }}</h6>
                <p>
                  <a :href="`https://${$store.state.user.permission.Domain}/cart/confirm?id=${Data._id}`" class="text-info mr-2" target="_blank">{{ `https://${$store.state.user.permission.Domain}/cart/confirm?id=${Data._id}` }}</a>
                  <a href="#" v-c-tooltip="{content: $t('Order.CopyOrderLink'), placement: 'top'}" @click="Copy(`https://${$store.state.user.permission.Domain}/cart/confirm?id=${Data._id}`)" class="text-dark">
                    <i class="far fa-copy" />
                  </a>
                </p>
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.CreateTime') }}</h6>
                <p>{{ $dayjs(Data.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}</p>
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.OrderStatus') }}</h6>
                <p>
                  <CBadge :color="StatusClassOption[Data.Status.Order]">
                    {{ $t('Order.OrderStatusList.' + Data.Status.Order) }}
                  </CBadge>
                </p>
              </div>
              <div v-if="Data.MemberInfo.ReceiverDate" class="mb-3">
                <h6>{{ $t('Order.ReceiverDate') }}</h6>
                <p v-if="!Edit.Info">
                  {{ Data.MemberInfo.ReceiverDate || '--' }}
                </p>
                <v-date-picker v-if="Edit.Info" v-model="UpdateData.Info['MemberInfo.ReceiverDate']" mode="date" :model-config="{type: 'string',mask: 'YYYY-MM-DD'}">
                  <template v-slot="{ inputValue, inputEvents }">
                    <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                      <template #append-content>
                        <CIcon name="cil-calendar"/>
                      </template>
                    </CInput>
                  </template>
                </v-date-picker>
              </div>
            </CCardBody>
          </CCard>
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.BuyerInfo') }}
              <div class="card-header-actions d-flex">
                <CButton color="success" type="button" size="sm" class="py-0 px-2 mr-1" @click="OpenNotifyModal('Custom')">
                  {{ $t('Order.SendNotifyMessage') }}
                </CButton>
                <CButton v-if="NotifyFeatures.includes('SMS')" color="info" type="button" size="sm" class="py-0 px-2 mr-1" @click="OpenSMSModal('Custom')">
                  {{ $t('Order.SendSMSMessage') }}
                </CButton>
                <CButton v-if="!Edit.BuyerInfo" color="secondary" type="button" size="sm" class="py-0 px-2" @click="EditData('BuyerInfo')">
                  {{ $t('Global.Edit') }}
                </CButton>
                <template v-if="Edit.BuyerInfo">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.BuyerInfo = false">
                    {{ $t('Global.Cancel') }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="Update('BuyerInfo')">
                    {{ $t('Global.Save') }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner color="info"/>
              </CElementCover>
              <div class="mb-3">
                <h6>{{ $t('Order.Buyer') }}</h6>
                <p v-if="!Edit.BuyerInfo"><CBadge class="mr-1" :color="(Data.UserID === '0' ? 'warning' : 'info')">{{ (Data.UserID === '0' ? '訪客' : '會員') }}</CBadge>{{ Data.MemberInfo.Name }}</p>
                <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.Name']" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.Phone') }}</h6>
                <p v-if="!Edit.BuyerInfo">{{ Data.MemberInfo.Phone }}</p>
                <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.Phone']" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.Email') }}</h6>
                <p v-if="!Edit.BuyerInfo">{{ Data.MemberInfo.Email }}</p>
                <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.Email']" />
              </div>
              <template v-if="Data.Logistics.Type === 'EXPRESS'">
                <div class="mb-3">
                  <h6>{{ $t('Order.City') }}</h6>
                  <p v-if="!Edit.BuyerInfo">{{ Data.MemberInfo.City }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.City']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.Area') }}</h6>
                  <p v-if="!Edit.BuyerInfo">{{ Data.MemberInfo.Area }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.Area']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.Address') }}</h6>
                  <p v-if="!Edit.BuyerInfo">{{ Data.MemberInfo.Address }}</p>
                  <CInput v-else size="sm" class="mb-3" v-model="UpdateData.BuyerInfo['MemberInfo.Address']" />
                </div>
              </template>
            </CCardBody>
          </CCard>
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.PaymentInfo') }}
              <div class="card-header-actions d-flex">
                <CButton v-if="false" color="danger" type="button" size="sm" class="py-0 px-2 mr-1">
                  {{ $t('Order.Refund') }}
                </CButton>
                <CButton v-if="Data.Status.Payment === 'U'" color="warning" type="button" size="sm" class="py-0 px-2 mr-1" @click="OpenNotifyModal('Unpaid')">
                  {{ $t('Order.NotifyPayment') }}
                </CButton>
                <CButton v-if="!Edit.Payment" color="secondary" type="button" size="sm" class="py-0 px-2" @click="EditData('Payment')">
                  {{ $t('Global.Edit') }}
                </CButton>
                <template v-if="Edit.Payment">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.Payment = false">
                    {{ $t('Global.Cancel') }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="Update('Payment')">
                    {{ $t('Global.Save') }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner color="info"/>
              </CElementCover>
              <div class="mb-3">
                <h6>{{ $t('Order.PaymentStatus') }}</h6>
                <p v-if="!Edit.Payment">
                  <CBadge :color="StatusClassOption[Data.Status.Payment]">
                    {{ $t('Order.PaymentStatusList.' + Data.Status.Payment) }}
                  </CBadge>
                </p>
                <CSelect v-else size="sm" :options="PaymentStatusList" v-model="UpdateData.Payment['Status.Payment']" :value.sync="UpdateData.Payment['Status.Payment']" :placeholder="$t('Global.PleaseSelect')" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.PaymentType') }}</h6>
                <p v-if="!Edit.Payment">{{ Data.Payment.Name }}({{ $t('Payment.PaymentSystem.' + Data.Payment.System) }})</p>
                <CSelect v-else size="sm" :options="PaymentListOptions" v-model="Data.Payment.ID" :value.sync="Data.Payment.ID" :placeholder="$t('Global.PleaseSelect')" @change="ChangePayment()" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.PaymentFee') }}</h6>
                <p v-if="!Edit.Payment">
                  {{ $store.state.currency }}{{ Data.Payment.Fee | Currency }}
                </p>
                <CInput v-else size="sm" v-model="UpdateData.Payment['Payment.Fee']" />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol lg="7" col="12">
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.TravelInvoiceInfo') }}
              <div class="card-header-actions d-flex">
<!--                <CButton v-if="!Data.Invoice.Number" color="success" type="button" size="sm" class="py-0 px-2 mr-1" @click="IssueInvoice()">-->
<!--                  {{ $t('Order.IssueInvoice') }}-->
<!--                </CButton>-->
                <CButton v-if="!Edit.Invoice" color="secondary" type="button" size="sm" class="py-0 px-2" @click="EditData('Invoice')">
                  {{ $t("Global.Edit") }}
                </CButton>
                <template v-if="Edit.Invoice">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.Invoice = false">
                    {{ $t("Global.Cancel") }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="Update('Invoice')">
                    {{ $t("Global.Save") }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner color="info"/>
              </CElementCover>
              <div class="mb-3">
                <h6>{{ $t('Order.Invoice.Type') }}</h6>
                <p v-if="!Edit.Invoice">{{ $t("Order.InvoiceType." + Data.Invoice.Type) }}</p>
                <CSelect v-else size="sm" :options="InvoiceTypeOptions" v-model="UpdateData.Invoice['Invoice.Type']" :value.sync="UpdateData.Invoice['Invoice.Type']" :placeholder="$t('Global.PleaseSelect')" @change="CheckInvoiceType" />
              </div>
              <div class="mb-3">
                <h6>{{ $t('Order.Invoice.Carrier') }}</h6>
                <p v-if="!Edit.Invoice">{{ Data.Invoice.Carrier || "--" }}</p>
                <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.Carrier']" />
              </div>
              <template v-if="Data.Invoice.Type === 'COM'">
                <div class="mb-3">
                  <h6>{{ $t('Order.Invoice.Header') }}</h6>
                  <p v-if="!Edit.Invoice">{{ Data.Invoice.Header || "--" }}</p>
                  <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.Header']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.Invoice.UniCode') }}</h6>
                  <p v-if="!Edit.Invoice">{{ Data.Invoice.UniCode || "--" }}</p>
                  <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.UniCode']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.Invoice.Address') }}</h6>
                  <p v-if="!Edit.Invoice">{{ Data.Invoice.Address || "--" }}</p>
                  <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.Address']" />
                </div>
              </template>
              <template v-else>
                <div class="mb-3">
                  <h6>{{ $t('Order.Invoice.IssueDate') }}</h6>
                  <p v-if="!Edit.Invoice">{{ Data.Invoice.IssueDate || '--' }}</p>
                  <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.IssueDate']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.Invoice.Number') }}</h6>
                  <p v-if="!Edit.Invoice">{{ Data.Invoice.Number || '--' }}</p>
                  <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.Number']" />
                </div>
                <div class="mb-3">
                  <h6>{{ $t('Order.Invoice.RandomNumber') }}</h6>
                  <p v-if="!Edit.Invoice">{{ Data.Invoice.RandomNumber || '--' }}</p>
                  <CInput v-else type="text" size="sm" v-model="UpdateData.Invoice['Invoice.RandomNumber']" />
                </div>
              </template>
            </CCardBody>
          </CCard>
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">
              {{ $t('Order.ItemList') }}
              <div class="card-header-actions d-flex">
                <CButton  v-if="!Edit.ProductInfo" color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="EditData('ProductInfo')">
                  {{ $t('Global.Edit') }}
                </CButton>
                <template v-if="Edit.ProductInfo">
                  <CButton color="secondary" type="button" size="sm" class="py-0 px-2 mr-1" @click="Edit.ProductInfo = false">
                    {{ $t('Global.Cancel') }}
                  </CButton>
                  <CButton color="danger" type="button" size="sm" class="py-0 px-2" @click="UpdateProductInfo()">
                    {{ $t('Global.Save') }}
                  </CButton>
                </template>
              </div>
            </CCardHeader>
            <CCardBody class="p-0">
              <CDataTable
                :loading="Loading || Submit"
                :items="Data.ProductInfo"
                :fields="ProductListField"
                addTableClasses="mb-0"
                responsive
                hover
              >
                <template #Cover="{item}">
                  <td>
                    <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
                  </td>
                </template>
                <template #Name="{item}">
                  <td>
                    <CBadge class="mr-1" v-if="item.Variable.GuestTypeName" color="info">{{ item.Variable.GuestTypeName }}</CBadge><b>{{ item.Name }}</b><br>
                    <div v-if="item.Variable.StartLocation">
                      <small class="p-text-secondary">
                        <span>
                          {{ item.Variable.StartLocation }}
                          <span v-if="item.Variable.StartLocationDescription">({{ item.Variable.StartLocationDescription }})</span>
                        </span>
                        <span v-if="item.Variable.EndLocation">
                          ~ {{ item.Variable.EndLocation }}
                            <span v-if="item.Variable.EndLocationDescription">({{ item.Variable.EndLocationDescription }})</span>
                        </span>
                      </small>
                    </div>
                    <div v-if="item.Brand" class="p-text-dark">
                      <small>{{ item.Brand }}</small>
                    </div>
                  </td>
                </template>
                <template #Number="{item}">
                  <td v-if="item.Variable.ScheduleList">
                    <b>{{ item.Variable.ScheduleList.Number }}</b><br>
                    <small>{{ $dayjs(item.Variable.ScheduleList.Time).format('HH:mm') }} ~ {{ $dayjs(item.Variable.ScheduleList.Time).add(item.Variable.ScheduleList.Duration, 'm').format('HH:mm') }}</small><br>
                    <CBadge v-if="item.Variable.DropOffDirection" :color="item.Variable.DropOffDirection === 'outbound' ? 'success' : 'dark'">{{ item.Variable.DropOffDirection === 'outbound' ? '去程' : '回程' }}</CBadge>
                  </td>
                  <td v-else>
                    {{ item.SeriesNum }}
                  </td>
                </template>
                <template #Quantity="{item, index}">
                  <td>
                    <template v-if="!Edit.ProductInfo">
                      {{ item.Quantity }}
                    </template>
                    <CInput v-else type="number" size="sm" v-model="UpdateData.ProductInfo.ProductInfo[index].Quantity" />
                  </td>
                </template>
                <template #Amount="{item, index}">
                  <td>
                    <template v-if="!Edit.ProductInfo">
                      <span class="font-weight-bold text-danger">{{ $store.state.currency }}{{ Math.round(item.Amount.Actual * item.Discount) | Currency }}</span>
                    </template>
                    <CInput v-else type="number" size="sm" v-model="UpdateData.ProductInfo.ProductInfo[index].Amount.Actual" />
                  </td>
                </template>
                <template #footer>
                  <tfoot>
                  <tr>
                    <td class="font-weight-bold py-1 pt-2">
                      旅程日期
                    </td>
                    <td colspan="3" class="py-1 pt-2" />
                    <td class="py-1 pt-2">
                      {{ $dayjs(Data.Variable.StartDate).format('YYYY/M/D') }}
                      <template v-if="Data.Variable.EndDate">
                        ~ {{ $dayjs(Data.Variable.EndDate).format('YYYY/M/D') }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="border-0 font-weight-bold py-1 pt-2">
                      {{ $t('Order.ProductInfo.ProductTotal') }}
                    </td>
                    <td colspan="3" class="border-0 py-1 pt-2" />
                    <td class="border-0 py-1 pt-2">
                      {{ $store.state.currency }}{{ Data.Amount | Currency }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold border-0 py-1">
                      {{ $t('Order.OfferAmount') }}
                    </td>
                    <td colspan="3" class="border-0 py-1 pb-2" />
                    <td class="border-0 py-1 pb-2">
                      <span v-if="Data.Offer.length === 0">{{ $store.state.currency }}{{ 0 - Data.OfferAmount | Currency }}</span>
                      <a v-else href="javaScript:void(0)" @click="OfferModel = true">{{ $store.state.currency }}{{ 0 - Data.OfferAmount | Currency }}</a>
                    </td>
                  </tr>
                  <tr v-if="Data.Wallet">
                    <td class="font-weight-bold border-0 py-1">
                      {{ $t('Order.BonusAmount') }}
                    </td>
                    <td colspan="3" class="border-0 py-1 pb-2" />
                    <td class="border-0 py-1 pb-2">
                      {{ $store.state.currency }}{{ 0 - parseInt(Data.Wallet.Bonus.Value) | Currency }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold border-0 py-1 pb-2">
                      {{ $t('Order.ProductInfo.SubTotal') }} <i v-c-tooltip="{content: $t('Order.ProductInfo.SubTotalInfo'),placement: 'right'}" class="fas fa-question-circle text-info" />
                    </td>
                    <td colspan="3" class="border-0 py-1 pb-2" />
                    <td class="border-0 py-1">
                      {{ $store.state.currency }}{{ Data.RealAmount | Currency }}
                    </td>
                  </tr>
                  <tr v-if="Data.Logistics.Fee > 0">
                    <td class="font-weight-bold border-0 py-1">
                      {{ $t('Order.LogisticsFee') }}
                    </td>
                    <td colspan="3" class="border-0 py-1" />
                    <td class="border-0 py-1">
                      {{ $store.state.currency }}{{ Data.Logistics.Fee | Currency }}
                    </td>
                  </tr>
                  <tr v-if="Data.Payment.Fee > 0">
                    <td class="font-weight-bold border-0 py-1 pb-2">
                      {{ $t('Order.PaymentFee') }}
                    </td>
                    <td colspan="3" class="border-0 py-1 pb-2" />
                    <td class="border-0 py-1 pb-2">
                      {{ $store.state.currency }}{{ Data.Payment.Fee | Currency }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold pt-2">
                      {{ $t('Order.ProductInfo.Total') }}
                    </td>
                    <td colspan="3" class="pt-2" />
                    <td class="font-weight-bold pt-2">
                      {{ $store.state.currency }}{{ Data.TotalAmount | Currency }}
                    </td>
                  </tr>
                  </tfoot>
                </template>
              </CDataTable>
            </CCardBody>
          </CCard>
          <CCard accentColor="info">
            <CCardHeader tag="h5" class="text-info">{{ $t('Order.OrderLog') }}</CCardHeader>
            <CCardBody>
              <OrderLog :OrderData="Data" :ShowLogisticsLog="false" />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </template>
    <CElementCover v-if="(Loading === true)" :opacity="0.75" class="py-5 bg-transparent">
      <CSpinner color="info"/>
    </CElementCover>
    <component
      :is="Component"
      :Single="true"
      :Toggle.sync="ToggleModal"
      :MemberInfo="Data.MemberInfo"
      :MessageType.sync="MessageType"
      :OrderNum.sync="SelectOrderNum"
      :OrderData.sync="Data"
      :ChooseProductType="ChooseProductType"
      @ChooseProduct="UpdateProductInfo"
    />
    <CModal id="OfferModel" class="ActionModel" size="lg" :show.sync="OfferModel" :centered="true" :closeOnBackdrop="!Submit">
      <CDataTable
        :loading="Loading"
        :items="Data.Offer"
        :fields="OfferListField"
        addTableClasses="mb-0"
        responsive
        hover
      >
        <template #Name="{item}">
          <td>
            {{item.Name}}
          </td>
        </template>
        <template #Space="">
          <td>
          </td>
        </template>
        <template #OfferAmount="{item}">
          <td>
            {{ $store.state.currency }}{{-item.CurrentOfferAmount | Currency }}
          </td>
        </template>
        <template #footer>
          <tr>
            <td class="font-weight-bold pt-2">
              {{ $t('Order.ProductInfo.Total') }}
            </td>
            <td class="w-25">
            </td>
            <td class="font-weight-bold pt-2">
              {{ $store.state.currency }}{{ Data.OfferAmount * -1 | Currency }}
            </td>
          </tr>
        </template>
      </CDataTable>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Order.OfferInfo') }}</h5>
          <CButtonClose @click="OfferModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#OfferModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton id="OfferModelSubmit" @click="OfferModel = false" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "訂單明細"
}
}
</route>

<script>
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'OrderNum',
  layout: 'manage',
  components: {
    MediaStore: () => import('@/components/public/Media'),
    OrderLog: () => import('@/components/order/Timeline'),
    Editor
  },
  filters: {
    Currency (value) {
      if (!value) value = 0
      return '$' + value.toString()
    }
  },
  data () {
    return {
      TinyMCE: TinyMCESetting,
      Accordion: false,
      LogisticsPackageAccordion: false,
      AdditionalReceiverAccordion: false,
      Features: [],
      LogisticsFeatures: [],
      NotifyFeatures: [],
      ToggleModal: false,
      MessageType: '',
      Component: null,
      SelectOrderNum: '',
      Data: {
        Amount: 0,
        CreateTime: '',
        Currency: '',
        CurrencyValue: 1,
        Invoice: {
          Type: '',
          Carrier: '',
          Header: '',
          Number: '',
          UniCode: ''
        },
        LogisticsData: {},
        Logistics: '',
        MemberInfo: {},
        Note: '',
        Offer: [],
        OrderNum: '',
        Payment: '',
        ProductInfo: [],
        RealAmount: 0,
        Status: {
          Payment: 'P',
          Order: 'P',
          Logistics: 'P'
        },
        Taxonomy: '',
        TotalAmount: 0,
        UpdateTime: '',
        UserID: ''
      },
      OfferModel: false,
      ShowOffer: 'All',
      Edit: {
        Payment: false,
        Logistics: false,
        BuyerInfo: false,
        Info: false,
        ProductInfo: false,
        Message: false,
        Invoice: false,
      },
      UpdateData: {
        BuyerInfo: {},
        Payment: {},
        Logistics: {},
        Info: {},
        ProductInfo: {},
        Message: {},
        Invoice: {}
      },
      ChooseProductType: null,
      PaymentList: [],
      PaymentListOptions: [],
      LogisticsList: [],
      LogisticsListOptions: [],
      Loading: true,
      Submit: false,
      StatusClassOption: {
        'P': 'warning',
        'A': 'primary',
        'U': 'warning',
        'C': 'secondary',
        'F': 'danger',
        'N': 'danger',
        'Y': 'success',
        'H': 'info',
        'S': 'primary',
        'O': 'info',
      },
    }
  },
  computed: {
    ProductListField () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'Number', label: '編號' },
        { key: 'Quantity', label: this.$t('Order.ProductInfo.Quantity') },
        { key: 'Amount', label: this.$t('Order.ProductInfo.Amount') }
      ]
    },
    OfferListField () {
      return [
        { key: 'Name', label: this.$t('Offer.ActivityName') },
        { key: 'Space', label: '' },
        { key: 'OfferAmount', label: this.$t('Offer.DiscountValue') }
      ]
    },
    ProductPackageListField () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') },
        { key: 'Quantity', label: this.$t('Order.ProductInfo.Quantity') },
      ]
    },
    UnpackedProductItems () {
      const PackageData = this.Data.Packing.Packages
      if (!PackageData || PackageData.length === 0) {
        return []
      }
      return PackageData[PackageData.length - 1].UnfitItems
    },
    PaymentStatusList() {
      return Object.keys(this.$t('Order.PaymentStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.PaymentStatusList')[status]
        }
      })
    },
    LogisticsStatusList() {
      return Object.keys(this.$t('Order.LogisticsStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.LogisticsStatusList')[status]
        }
      })
    },
    OrderStatusList() {
      return Object.keys(this.$t('Order.OrderStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.OrderStatusList')[status]
        }
      })
    },
    InvoiceTypeOptions() {
      return Object.keys(this.$t('Order.InvoiceType')).map(type => {
        return {
          value: type,
          label: this.$t('Order.InvoiceType')[type]
        }
      })
    }
  },
  created() {
    document.title = `${this.$t('Order.Info')} #${this.$route.params.orderNum}`
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    this.Features = PermissionSession.Features.Order || []
    this.LogisticsFeatures = PermissionSession.Features.System.Logistics || []
    this.NotifyFeatures = PermissionSession.Features.Notify || []
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GerOrder()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GerOrder() {
      return this.$store.dispatch('InnerRequest', {
        url: '/order/detail',
        method: 'get',
        params: {
          orderNum: this.$route.params.orderNum
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    EditData(type) {
      this.Edit[type] = true
      switch (type) {
        case 'ProductInfo':
          this.UpdateData[type].ProductInfo = this.Data.ProductInfo
          break
        case 'Logistics':
          this.GetShipmentList()
          this.UpdateData[type]['Status.Logistics'] = this.Data.Status.Logistics
          this.UpdateData[type]['Logistics.ID'] = this.Data.Logistics.ID
          this.UpdateData[type]['Logistics.Name'] = this.Data.Logistics.Name
          this.UpdateData[type]['Logistics.System'] = this.Data.Logistics.System
          this.UpdateData[type]['Logistics.Method'] = this.Data.Logistics.Method
          this.UpdateData[type]['Logistics.Type'] = this.Data.Logistics.Type
          this.UpdateData[type]['Logistics.Fee'] = this.Data.Logistics.Fee
          this.UpdateData[type]['MemberInfo.ReceiverName'] = this.Data.MemberInfo.ReceiverName
          this.UpdateData[type]['MemberInfo.ReceiverPhone'] = this.Data.MemberInfo.ReceiverPhone
          this.UpdateData[type]['MemberInfo.ReceiverEmail'] = this.Data.MemberInfo.ReceiverEmail
          if (this.Data.Logistics.Type === 'EXPRESS') {
            this.UpdateData[type]['MemberInfo.ReceiverCity'] = this.Data.MemberInfo.ReceiverCity
            this.UpdateData[type]['MemberInfo.ReceiverArea'] = this.Data.MemberInfo.ReceiverArea
            this.UpdateData[type]['MemberInfo.ReceiverAddress'] = this.Data.MemberInfo.ReceiverAddress
            this.UpdateData[type]['MemberInfo.ReceiverZipCode'] = this.Data.MemberInfo.ReceiverZipCode
            this.UpdateData[type]['MemberInfo.ReceiverAddress2'] = this.Data.MemberInfo.ReceiverAddress2
            this.UpdateData[type]['MemberInfo.ReceiverAddress3'] = this.Data.MemberInfo.ReceiverAddress3
          }
          if (this.Data.Logistics.Type === 'SUPERSTORE') {
            this.UpdateData[type]['Logistics.Detail.StoreID'] = this.Data.Logistics.Detail.StoreID
            this.UpdateData[type]['Logistics.Detail.StoreName'] = this.Data.Logistics.Detail.StoreName
          }
          break
        case 'BuyerInfo':
          this.UpdateData[type]['MemberInfo.Name'] = this.Data.MemberInfo.Name
          this.UpdateData[type]['MemberInfo.Phone'] = this.Data.MemberInfo.Phone
          this.UpdateData[type]['MemberInfo.Email'] = this.Data.MemberInfo.Email
          this.UpdateData[type]['MemberInfo.City'] = this.Data.MemberInfo.City
          this.UpdateData[type]['MemberInfo.Area'] = this.Data.MemberInfo.Area
          this.UpdateData[type]['MemberInfo.Address'] = this.Data.MemberInfo.Address
          break
        case 'Message':
          this.UpdateData[type]['CustomMessage'] = {
            Content: '',
            Origin: 'Manage'
          }
          break
        case 'Info':
          this.UpdateData[type].Note = this.Data.Note
          this.UpdateData[type]['Variable.AdminNote'] = this.Data.Variable.AdminNote
          this.UpdateData[type]['Variable.ShipmentNote'] = this.Data.Variable.ShipmentNote
          this.UpdateData[type]['MemberInfo.ReceiverDate'] = this.Data.MemberInfo.ReceiverDate
          break
        case 'Payment':
          this.GetPaymentList()
          this.UpdateData[type]['Status.Payment'] = this.Data.Status.Payment
          this.UpdateData[type]['Payment.Fee'] = this.Data.Payment.Fee
          this.UpdateData[type]['Payment.ID'] = this.Data.Payment.ID
          this.UpdateData[type]['Payment.Name'] = this.Data.Payment.Name
          this.UpdateData[type]['Payment.System'] = this.Data.Payment.System
          this.UpdateData[type]['Payment.Method'] = this.Data.Payment.Method
          this.UpdateData[type]['Payment.Type'] = this.Data.Payment.Type
          break
        case 'Processed':
          this.UpdateData.Message['CustomMessage'] = undefined
          this.UpdateData.Message['Variable.MessageProcessed'] = !this.Data.Variable.MessageProcessed
          this.Update('Message')
          break
        case 'Invoice':
          this.UpdateData[type]['Invoice.Type'] = this.Data.Invoice.Type
          this.UpdateData[type]['Invoice.Carrier'] = this.Data.Invoice.Carrier
          this.UpdateData[type]['Invoice.Header'] = this.Data.Invoice.Header
          this.UpdateData[type]['Invoice.UniCode'] = this.Data.Invoice.UniCode
          this.UpdateData[type]['Invoice.Address'] = this.Data.Invoice.Address
          break;
      }
    },
    Update(type) {
      if (this.Submit === true) {
        return Promise.resolve(true)
      }
      this.Submit = true
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        url: '/order/edit',
        method: 'post',
        data: {
          orderNum: this.Data.OrderNum,
          origin: 'Manage',
          updateData: this.UpdateData[type]
        }
      }).then(() => {
        this.Edit[type] = false
        this.Submit = false
        this.$Progress.finish()
        this.GerOrder()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Order/OrderChangeSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        this.Submit = false
        this.Edit[type] = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Order/OrderChangeFail') + error.Message,
          type: (error.type ? error.type : 'error')
        })
      })
    },
    CheckInvoiceType () {
      if (this.UpdateData.Invoice['Invoice.Type'] === 'INVALID') {
        this.UpdateData.Invoice['Invoice.Number'] = ''
        this.UpdateData.Invoice['Invoice.RandomNumber'] = ''
        this.UpdateData.Invoice['Invoice.IssueDate'] = ''
        this.UpdateData.Invoice['Invoice.RelateNumber'] = ''
      }
    },
    OpenCreateLogisticsTrackingModal() {
      this.Component= () => import('@/components/order/CreateLogisticsTracking')
      setTimeout(() => {
        this.SelectOrderNum = this.Data.OrderNum
        this.ToggleModal = true
      }, 300)
    },
    OpenProductListModal(taxonomy) {
      this.EditData('ProductInfo')
      this.ChooseProductType = taxonomy
      this.Component = () => import('@/components/product/OrderProductList')
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    OpenNotifyModal (messageType) {
      this.MessageType = messageType
      this.Component = () => import('@/components/order/SendNotify')
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    OpenSMSModal (messageType) {
      this.MessageType = messageType
      this.Component = () => import('@/components/order/SendSMS')
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    ChooseProduct(items) {
      this.$set(this.Comment, 'Detail', items[0])
      this.Comment.SeriesNum = items[0].SeriesNum
      this.Comment.CustomSeriesNum = items[0].CustomSeriesNum
    },
    IssueInvoice () {
      if (this.Data.Status.Payment !== 'Y') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Order/NeedPaymentToIssue'),
          type: 'warn',
          duration: 10000
        })
        return false
      }
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.Order/QuestionIssueInvoice'),
        text: this.$t('Message.Order/QuestionIssueInvoiceDescription'),
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start()
          return this.$store.dispatch('InnerRequest', {
            url: '/order/issueInvoice',
            method: 'post',
            data: {
              OrderData: {
                OrderNum: this.Data.OrderNum,
                UserID: this.Data.UserID,
                Variable: this.Data.Variable,
                Wallet: this.Data.Wallet,
                TotalAmount: this.Data.TotalAmount,
                RealAmount: this.Data.RealAmount,
                OfferAmount: this.Data.OfferAmount,
                Amount: this.Data.Amount,
                Payment: this.Data.Payment,
                Logistics: this.Data.Logistics,
                Invoice: this.Data.Invoice,
                MemberInfo: this.Data.MemberInfo,
                ProductInfo: this.Data.ProductInfo
              }
            }
          }).then(() => {
            this.$Progress.finish()
            this.GerOrder()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Order/IssueInvoiceSuccess'),
              type: 'success'
            })
          }).catch((error) => {
            this.$Progress.fail()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Order/IssueInvoiceFail') + error.msg,
              type: (error.type ? error.type : 'error')
            })
          })
        }
      })
    },
    VoidInvoice () {},
    GetPaymentList() {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/payment/list',
        method: 'get'
      }).then(({data}) => {
        this.PaymentList = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.PaymentListOptions = this.PaymentList.map((item) => {
          return {
            label: `${item.Name} (${this.$t('Order.PaymentType')}: ${this.$t('Payment.PaymentMethods.' + item.Method)}，${this.$t('Order.PaymentSystem')}: ${this.$t('Payment.PaymentSystem.' + item.System)}，${this.$t('Payment.Fee')}: ${item.Fee || 0})`,
            value: item.ID
          }
        })
      }).catch((err) => {
        throw err
      })
    },
    GetShipmentList() {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/list',
        method: 'get'
      }).then(({data}) => {
        this.LogisticsList = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.LogisticsListOptions = this.LogisticsList.map((item) => {
          return {
            label: `${item.Name} (${this.$t('Order.LogisticsType')}: ${this.$t('Logistics.LogisticsMethods.' + item.Method)}，${this.$t('Order.LogisticsSystem')}: ${this.$t('Logistics.LogisticsSystem.' + item.System)}，${this.$t('Logistics.Fee')}: ${item.Fee}，${this.$t('Logistics.ServiceLocation')}: ${this.$t('Logistics.ServiceLocationOptions.' + item.ServiceLocation)})`,
            value: item.ID
          }
        })
      }).catch((err) => {
        throw err
      })
    },
    ChangePayment () {
      const NewPayment = this.PaymentList.find((item) => item.ID === this.Data.Payment.ID)
      if (NewPayment) {
        this.UpdateData.Payment['Payment.ID'] = NewPayment.ID
        this.UpdateData.Payment['Payment.Name'] = NewPayment.Name
        this.UpdateData.Payment['Payment.System'] = NewPayment.System
        this.UpdateData.Payment['Payment.Method'] = NewPayment.Method
        this.UpdateData.Payment['Payment.Type'] = NewPayment.Type
      }
    },
    ChangeLogistics () {
      const NewLogistics = this.LogisticsList.find((item) => item.ID === this.Data.Logistics.ID)
      if (NewLogistics) {
        this.UpdateData.Logistics['Logistics.ID'] = NewLogistics.ID
        this.UpdateData.Logistics['Logistics.Name'] = NewLogistics.Name
        this.UpdateData.Logistics['Logistics.System'] = NewLogistics.System
        this.UpdateData.Logistics['Logistics.Method'] = NewLogistics.Method
        this.UpdateData.Logistics['Logistics.Type'] = NewLogistics.Type
      }
    },
    UpdateProductInfo (value) {
      if (value) {
        this.UpdateData.ProductInfo.ProductInfo.push(value)
      }
      this.UpdateData.ProductInfo.ProductInfo = this.UpdateData.ProductInfo.ProductInfo.map(item => {
        item.Quantity = parseInt(item.Quantity || '0')
        item.Amount.Actual = parseInt(item.Amount.Actual || '0')
        return item
      })
      this.UpdateData.ProductInfo.Amount = this.UpdateData.ProductInfo.ProductInfo.map(item => Math.round(item.Amount?.Actual * item.Discount) * item.Quantity || 0).reduce((a, c) => a + c, 0)
      this.UpdateData.ProductInfo.RealAmount = this.UpdateData.ProductInfo.Amount - this.Data.OfferAmount - (this.Data.Wallet?.Bonus?.Value || 0)
      this.UpdateData.ProductInfo.TotalAmount = this.UpdateData.ProductInfo.Amount - this.Data.OfferAmount + this.Data.Logistics.Fee + this.Data.Payment.Fee - (this.Data.Wallet?.Bonus?.Value || 0)
      this.Edit.ProductInfo = false
      return this.Update('ProductInfo')
    },
    Copy(_link) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/CopySuccess'),
          type: 'success'
        })
        return navigator.clipboard.writeText(_link)
      }
      return Promise.reject('The Clipboard API is not available.');
    },
    DeleteProductItem (index) {
      this.EditData('ProductInfo')
      return this.$swal({
        icon: 'info',
        title: '確認移除',
        text: '此動作無法還原，是否移除此商品？',
        showCancelButton: true,
        confirmButtonColor: '#e55353',
        confirmButtonText: this.$t('Global.Confirm'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        this.Edit.ProductInfo = false
        if (result.isConfirmed) {
          this.UpdateData.ProductInfo.ProductInfo.splice(index, 1)
          this.UpdateProductInfo()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.table {
  thead th {
    white-space: nowrap;
    &:first-child {
      width: 100px;
    }
  }
  td {
    &:first-child {
      white-space: nowrap;
    }
  }
}
</style>

<style lang="scss" scoped>
.mt-70 {
  margin-top: 70px
}

.mb-70 {
  margin-bottom: 70px
}

.vertical-timeline {
  width: 100%;
  position: relative;
  padding: 1.5rem 0 1rem
}

.vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 67px;
  height: 100%;
  width: 4px;
  background: #e9ecef;
  border-radius: .25rem
}

.vertical-timeline-element {
  position: relative;
  margin: 0 0 1rem
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  animation: cd-bounce-1 .8s
}

.vertical-timeline-element-icon {
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
}

.vertical-timeline-element-icon .badge-dot-xl {
  box-shadow: 0 0 0 5px #fff
}

.badge-dot-xl {
  width: 18px;
  height: 18px;
  position: relative
}

.badge:empty {
  display: inline-block
}

.badge-dot-xl::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: .25rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5px 0 0 -5px;
  background: #fff
}

.vertical-timeline-element-content {
  position: relative;
  margin-left: 90px;
}

.vertical-timeline-element-content .timeline-title {
  text-transform: uppercase;
  margin: 0 0 .5rem;
  font-weight: bold
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  display: block;
  position: absolute;
  left: -90px;
  top: 48%;
  transform: translateY(-48%);
  padding-right: 10px;
  text-align: right;
  color: #adb5bd;
  font-size: .7619rem;
  white-space: nowrap
}

.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both
}

.MessageContent {
  max-height: 400px;
  overflow: auto;
}
</style>
